import { Observable, gql } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getRefreshToken, setAccessToken, setRefreshToken } from '@holo/jwt';
import { ERROR_CODES } from '@holo/constants';
import { initApolloClient } from '../client';

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export const createRefreshTokenLink = (context: any) => {
  // @ts-ignore
  return onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      const { extensions } = graphQLErrors[0];

      // @ts-ignore
      if (extensions?.code === ERROR_CODES.ACCESS_TOKEN_EXPIRED) {
        const apolloClient = initApolloClient({ context });

        // @ts-ignore
        return new Observable((observer) => {
          const refreshToken = getRefreshToken(context);
          const { headers } = operation.getContext();

          if (!refreshToken) {
            return null;
          }

          return apolloClient
            .mutate({
              mutation: REFRESH_TOKEN_MUTATION,
              variables: {
                refreshToken,
              },
            })
            .then(({ data }) => {
              const { accessToken, refreshToken } = data.refreshToken;

              setAccessToken(accessToken, context);
              setRefreshToken(refreshToken, context);

              return operation.setContext(() => ({
                headers: {
                  ...headers,
                  authorization: `Bearer ${accessToken}`,
                },
              }));
            })
            .then(() => {
              const subscriber = {
                complete: observer.complete.bind(observer),
                error: observer.error.bind(observer),
                next: observer.next.bind(observer),
              };

              return forward(operation).subscribe(subscriber);
            });
        });
      }
    }

    return null;
  });
};
