import tinycolor from 'tinycolor2';
import { createTamagui, createTheme, createTokens, createFont } from 'tamagui';
import { shorthands } from '@tamagui/shorthands';
import { createAnimations } from '@tamagui/animations-css';

const animations = createAnimations({
  ultra: 'ease-in 100ms',
  fast: 'ease-in 150ms',
  medium: 'ease-in 300ms',
  slow: 'ease-in 450ms',
});

const interFont = createFont({
  family: 'var(--font-inter)',
  size: {
    true: 12,
    4: 16,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    18: 18,
    20: 20,
    24: 24,
    32: 32,
    40: 40,
    42: 42,
    52: 52,
    72: 72,
  },
  lineHeight: {
    true: 24,
    1: 17,
    2: 22,
    3: 25,
  },
  weight: {
    regular: '400',
    medium: '500',
    'semi-bold': '600',
  },
  letterSpacing: {
    true: 0,
    0: 0,
    1: 1,
  },
});

const createHoverVariant = (color: tinycolor.Instance) => {
  if (color.toName() === 'transparent') {
    return tinycolor('transparent');
  }

  if (color.toName() === 'white') {
    return tinycolor('#f2f2f2');
  }

  if (color.toName() === 'black') {
    return tinycolor('#333');
  }

  if (color.isDark()) {
    return color.lighten(4);
  }

  return color.darken(4);
};

const baseColors = {
  base: tinycolor('#fff'),
  basePale: tinycolor('#b2b2b2'),
  contrast: tinycolor('#000'),
  contrastPale: tinycolor('#4d4d4d'),
  accent: tinycolor('#2d6936'),
  accentPale: tinycolor('#e8f1ea'),
  darkGray: tinycolor('#6e6f71'),
  accentDeep: tinycolor('#3d904a'),
  border: tinycolor('#dddfe1'),
  subtlePale: tinycolor('#f0f1f3'),
  error: tinycolor('#c03636'),
  errorPale: tinycolor('#fadcdc'),
  warning: tinycolor('#b5801a'),
  warningPale: tinycolor('#faeedc'),
};

export const colors = {
  transparent: 'transparent',

  base: baseColors.base.toHexString(),
  baseHover: createHoverVariant(baseColors.base).toHexString(),

  basePale: baseColors.basePale.toHexString(),
  basePaleHover: createHoverVariant(baseColors.basePale).toHexString(),

  contrast: baseColors.contrast.toHexString(),
  contrastHover: createHoverVariant(baseColors.contrast).toHexString(),

  contrastPale: baseColors.contrastPale.toHexString(),
  contrastPaleHover: createHoverVariant(baseColors.contrastPale).toHexString(),

  accent: baseColors.accent.toHexString(),
  accentHover: createHoverVariant(baseColors.accent).toHexString(),

  accentPale: baseColors.accentPale.toHexString(),
  accentPaleHover: createHoverVariant(baseColors.accentPale).toHexString(),

  accentDeep: baseColors.accentDeep.toHexString(),
  accentDeepHover: createHoverVariant(baseColors.accentDeep).toHexString(),

  darkGray: baseColors.darkGray.toHexString(),
  darkGrayHover: createHoverVariant(baseColors.darkGray).toHexString(),

  border: baseColors.border.toHexString(),
  borderHover: createHoverVariant(baseColors.border).toHexString(),

  subtlePale: baseColors.subtlePale.toHexString(),
  subtlePaleHover: createHoverVariant(baseColors.subtlePale).toHexString(),

  error: baseColors.error.toHexString(),
  errorHover: createHoverVariant(baseColors.error).toHexString(),

  errorPale: baseColors.errorPale.toHexString(),
  errorPaleHover: createHoverVariant(baseColors.errorPale).toHexString(),

  warning: baseColors.warning.toHexString(),
  warningHover: createHoverVariant(baseColors.warning).toHexString(),

  warningPale: baseColors.warningPale.toHexString(),
  warningPaleHover: createHoverVariant(baseColors.warningPale).toHexString(),
};

const light = createTheme({
  // Tamagui required colors
  color: colors.contrast,
  backgroundStrong: 'inherit',
  backgroundPress: 'inherit',
  backgroundFocus: 'inherit',
  backgroundHover: 'inherit',
  borderColorHover: 'inherit',
  borderColorFocus: 'inherit',
  borderColorPress: 'inherit',
  colorPress: 'inherit',
  placeholderColor: colors.border,
  borderColor: colors.border,

  // Our colors
  background: colors.base,
  backgroundInverse: '#000000',
  base: colors.base,
  contrast: colors.contrast,
  accent: colors.accent,
  accentPale: colors.accentPale,
  darkGray: colors.darkGray,
  border: colors.border,
  subtlePale: colors.subtlePale,
  error: colors.error,
  errorPale: colors.errorPale,
  warning: colors.warning,
  warningPale: colors.warningPale,
});

const dark = createTheme({
  // Tamagui required colors
  color: 'white',
  backgroundStrong: 'inherit',
  backgroundPress: 'inherit',
  backgroundFocus: 'inherit',
  backgroundHover: 'inherit',
  borderColorHover: 'inherit',
  borderColorFocus: 'inherit',
  borderColorPress: 'inherit',
  colorPress: 'inherit',
  placeholderColor: '#1f2123',
  borderColor: '#1F2123',

  // Our colors
  background: '#000000',
  backgroundInverse: '#ffffff',
  base: '#000000',
  contrast: '#fff',
  accent: '#85B083',
  accentPale: '#0B120B',
  darkGray: colors.darkGray,
  border: '#1F2123',
  subtlePale: '#0d0e10',
  error: colors.error,
  errorPale: colors.errorPale,
  warning: colors.warning,
  warningPale: colors.warningPale,
});

const media = {
  xxs: { minWidth: 390 },
  xs: { minWidth: 660 },
  sm: { minWidth: 800 },
  md: { minWidth: 1020 },
  lg: { minWidth: 1280 },
  xl: { minWidth: 1355 },
  containerPadding: { minWidth: 1360 + 32 },
};

export const zIndex = {
  0: 0,
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
};

export const size = {
  true: 16,
  0: 0,
  0.5: 2,
  1: 4,
  1.5: 6,
  2: 8,
  2.5: 10,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
  8: 32,
  9: 36,
  10: 40,
  11: 44,
  12: 48,
  13: 52,
  14: 56,
  15: 60,
  16: 64,
  17: 68,
  18: 72,
  19: 76,
  20: 80,
  25: 100,
  30: 120,
  40: 160,
  4000: 4000,
};

export const space = {
  ...size,
  '-0': -0,
  '-0.5': -2,
  '-1': -4,
  '-1.5': -6,
  '-2': -8,
  '-2.5': -10,
  '-3': -12,
  '-4': -16,
  '-5': -20,
  '-6': -24,
  '-7': -28,
  '-8': -32,
  '-9': -36,
  '-10': -40,
};

export const tokens = createTokens({
  color: colors,
  radius: size,
  zIndex,
  space,
  size,
});

const themes = {
  light,
  dark,
};

const fonts = {
  heading: interFont,
  display: interFont,
  body: interFont,
};

const config = createTamagui({
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  fonts,
  themes,
  tokens,
  shorthands,
  media,
});

export type Config = typeof config;

declare module 'tamagui' {
  interface TamaguiCustomConfig extends Config {}
}

export default config;
