import { useMemo } from 'react';
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { isServer } from '@holo/platform';
import type { NormalizedCacheObject } from '@apollo/client';
import type { GetServerSidePropsContext } from 'next';
import { createAuthLink, createRefreshTokenLink, HttpLink, DebounceLink } from './links';

type Client = ApolloClient<NormalizedCacheObject>;
type InitialState = NormalizedCacheObject | undefined;

interface InitializeApollo {
  context?: GetServerSidePropsContext;
  initialState?: InitialState;
}

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: Client;

function createApolloClient(context?: GetServerSidePropsContext) {
  const AuthLink = createAuthLink(context);
  const RefreshTokenLink = createRefreshTokenLink(context);

  return new ApolloClient({
    ssrMode: isServer,
    link: from([RefreshTokenLink, AuthLink, DebounceLink, HttpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            product: {
              // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'Product',
                  id: args?.id,
                });
              },
            },
            order: {
              read(_, { args, toReference }) {
                return toReference({
                  __typename: 'Order',
                  id: args?.id,
                });
              },
            },
          },
        },
      },
    }),
  });
}

export function initApolloClient({ initialState, context }: InitializeApollo = {}): Client {
  const _apolloClient = apolloClient ?? createApolloClient(context);

  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }

  if (isServer) {
    return _apolloClient;
  }

  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function addApolloCache(client: Client, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function setupApollo(pageProps: any) {
  const initialState = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initApolloClient({ initialState }), [initialState]);
}
