import { Button, Icon, Text, VisuallyHidden, XStack } from '@holo/ui';
import type { DrawerHeaderProps } from './DrawerTypes';

const DrawerHeader = ({ closeButtonRef, close, dismissType, title }: DrawerHeaderProps) => {
  return (
    <XStack px="$5" py="$5" jc="space-between">
      {typeof title === 'string' ? (
        <Text fontSize="$24" fontWeight="$medium">
          {title}
        </Text>
      ) : (
        title
      )}
      <Button
        ref={closeButtonRef}
        onPress={close}
        variant='naked'
        width={24}
        height={24}
        minWidth={24}
        minHeight={24}
        hoverStyle={{ backgroundColor: 'transparent' }}
        pressStyle={{ backgroundColor: 'transparent' }}
        borderWidth={0}
        display="block"
      >
        <Icon icon={dismissType === 'close' ? 'close' : 'arrowLeft'} />
        <VisuallyHidden>
          <Text>{dismissType === 'close' ? 'Close' : 'Back'}</Text>
        </VisuallyHidden>
      </Button>
    </XStack>
  );
};

export default DrawerHeader;
