import { useReactiveVar } from '@holo/client';
import { drawersVar } from '@/store';

export const useDrawers = () => {
  const drawers = useReactiveVar(drawersVar);

  const closeLast = () => {
    drawersVar({
      currentId: undefined,
      ids: drawers.ids.slice(0, -1),
    });
  };

  const closeAll = () => {
    drawersVar({
      currentId: undefined,
      ids: [],
    });
  };

  return {
    drawers,
    closeLast,
    closeAll,
  };
};
