import { Container, XStack, Text, YStack, ListItem, Circle } from '@holo/ui';
import { Logo } from '@holo/ui/assets';
import { Link } from '@holo/link';
import { VisuallyHidden } from '@holo/ui';

const Footer = () => {
  return (
    <XStack tag="footer" borderTopWidth={1} borderTopColor="$border" backgroundColor="$background" py="$12">
      <Container>
        <XStack tag="nav" flexDirection="column" $sm={{ flexDirection: 'row', jc: 'space-between', ai: 'flex-start' }}>
          <YStack display="none" $md={{ display: 'block' }}>
            <Link href="/">
              <VisuallyHidden>
                <Text>Go to homepage</Text>
              </VisuallyHidden>
              <Logo />
            </Link>
          </YStack>

          <YStack tag="ul" mb="$10">
            <ListItem px="$0" py="$0" mb="$1">
              <Text fontWeight="$semi-bold" fontSize="$16">
                Platform
              </Text>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/platform#marketplace">
                <Text fontSize="$14" color="$darkGray">
                  Marketplace
                </Text>
              </Link>
            </ListItem>
            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/platform#task-manager">
                <Text fontSize="$14" color="$darkGray">
                  Task manager
                </Text>
              </Link>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/platform#crop-planner">
                <Text fontSize="$14" color="$darkGray">
                  Crop planner
                </Text>
              </Link>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/platform#harvesting">
                <Text fontSize="$14" color="$darkGray">
                  Harvesting
                </Text>
              </Link>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/platform#order-packing">
                <Text fontSize="$14" color="$darkGray">
                  Order packing
                </Text>
              </Link>
            </ListItem>
          </YStack>

          <YStack tag="ul" mb="$10">
            <ListItem px="$0" py="$0" mb="$1">
              <Text fontWeight="$semi-bold" fontSize="$16">
                Resources
              </Text>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="https://api.holo.ag/v1" target="_blank" rel="noopener noreferrer">
                <Text fontSize="$14" color="$darkGray">
                  API Reference
                </Text>
              </Link>
            </ListItem>
            <ListItem px="$0" py="$0" mt="$4">
              <Link href="https://gateway.holo.ag/graphql" target="_blank" rel="noopener noreferrer">
                <Text fontSize="$14" color="$darkGray">
                  GraphQL Reference
                </Text>
              </Link>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/signup?type=business">
                <Text fontSize="$14" color="$darkGray">
                  Create business account
                </Text>
              </Link>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/login">
                <Text fontSize="$14" color="$darkGray">
                  Sign in
                </Text>
              </Link>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/signup">
                <Text fontSize="$14" color="$darkGray">
                  Signup
                </Text>
              </Link>
            </ListItem>
          </YStack>

          <YStack tag="ul" mb="$10">
            <ListItem px="$0" py="$0" mb="$1">
              <Text fontWeight="$semi-bold" fontSize="$16">
                Company
              </Text>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="https://calendly.com/holoag/demo-holo-platform" target="_blank" rel="noopener noreferrer">
                <Text fontSize="$14" color="$darkGray">
                  Book a demo
                </Text>
              </Link>
            </ListItem>

            {/* <ListItem px="$0" py="$0" mt="$4">
              <Link href="/about">
                <Text fontSize="$14" color="$darkGray">
                  About
                </Text>
              </Link>
            </ListItem> */}
            {/* <ListItem px="$0" py="$0" mt="$4">
              <Link href="/careers">
                <Text fontSize="$14" color="$darkGray">
                  Careers
                </Text>
              </Link>
            </ListItem> */}
            {/* <ListItem px="$0" py="$0" mt="$4">
              <Link href="/blog">
                <Text fontSize="$14" color="$darkGray">
                  Blog
                </Text>
              </Link>
            </ListItem> */}
            {/* <ListItem px="$0" py="$0" mt="$4">
              <Link href="/changelog">
                <Text fontSize="$14" color="$darkGray">
                  Changelog
                </Text>
              </Link>
            </ListItem> */}
            <ListItem px="$0" py="$0" mt="$4">
              <Link href="https://www.linkedin.com/company/holoag" target="_blank" rel="noopener noreferrer">
                <Text fontSize="$14" color="$darkGray">
                  LinkedIn
                </Text>
              </Link>
            </ListItem>
            <ListItem px="$0" py="$0" mt="$4">
              <a href="mailto:hello@holo.ag">
                <Text fontSize="$14" color="$darkGray">
                  Contact us
                </Text>
              </a>
            </ListItem>
          </YStack>

          <YStack tag="ul" mb="$10">
            <ListItem px="$0" py="$0" mb="$1">
              <Text fontWeight="$semi-bold" fontSize="$16">
                Legal
              </Text>
            </ListItem>

            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/privacy-policy">
                <Text fontSize="$14" color="$darkGray">
                  Privacy Policy
                </Text>
              </Link>
            </ListItem>
            <ListItem px="$0" py="$0" mt="$4">
              <Link href="/terms-of-service">
                <Text fontSize="$14" color="$darkGray">
                  Terms of Service
                </Text>
              </Link>
            </ListItem>
          </YStack>
        </XStack>

        <XStack mt="$5" flexDirection="column" $sm={{ jc: 'space-between', flexDirection: 'row', mt: '$20' }}>
          <YStack mb="$2">
            <Text color="$darkGray">
              Copyright © {new Date().getFullYear()} Fellow Farmer Inc. All rights reserved.
            </Text>
          </YStack>

          <Link href="https://status.holo.ag" target="_blank" rel="noopener noreferrer">
            <XStack ai="center">
              <Circle width={10} height={10} backgroundColor="$accentDeep" mr="$1" />
              <Text color="$accentDeep">All systems normal</Text>
            </XStack>
          </Link>
        </XStack>
      </Container>
    </XStack>
  );
};

export default Footer;
